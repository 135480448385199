<template>
  <div style="margin: 30px">
    <div style="margin-bottom: 30px">
      <div>
        分享清单现金是否免登录：{{ value1 == 1 ? "是" : "否"
        }}<span class="btsed marle_10" @click="changeSecon(7)">更新</span>
      </div>
      <div>
        分享清单方式：{{ value2 == 1 ? "小程序" : "h5"
        }}<span class="btsed marle_10" @click="changeSecon(8)">更新</span>
      </div>
    </div>
    <div class="titlxtd">支付方式通道</div>
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      ref="commTable"
      :header-cell-style="{ background: '#F2F3F5' }"
      height="500px"
    >
      <el-table-column
        label="前端显示名称"
        align="center"
        prop="frontName"
        width="250"
      >
        <template slot-scope="scope">
          <div @click="showInput(scope.row, scope.$index, $event)">
            <el-input
              v-show="scope.row.Nametale"
              @input="
                scope.row.frontName = scope.row.frontName.replace(/[ ]/g, '')
              "
              v-model="scope.row.frontName"
              style="width: 200px"
              @blur="Changenameting(scope.row)"
              @keyup.enter.native="scope.row.Nametale = false"
            />
            <span v-show="!scope.row.Nametale">
              {{ scope.row.frontName || "-" }}
              <span
                class="click_fly el-icon-edit green"
                style="margin-left: 20px"
              >
              </span>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="支付logo" align="center" prop="">
        <template slot-scope="{ row }">
          <div class="img-box">
            <el-image
              style="width: 50px; height: 50px"
              :src="handleImageError(row.logoIconAddress)"
              :preview-src-list="[handleImageError(row.logoIconAddress)]"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="支付方式" align="center" prop="backendName" />
      <el-table-column label="支付类型" align="center" prop="typeName" />
      <el-table-column label="支付渠道" align="center" prop="channelName" />
      <el-table-column label="是否线上分账" align="center" prop="">
        <template slot-scope="{ row }">
          <div>{{ row.whetherSeparate == 0 ? "是" : "否" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="是否ERP结算" align="center" prop="">
        <template slot-scope="{ row }">
          <div>{{ row.erpAutoSettle == 0 ? "是" : "否" }}</div>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="{ row }">
          <div>
            <span class="btsed" @click="update(row)">更新</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改弹窗 -->
    <el-dialog
      v-if="dialogVisible"
      :title="changItem.title"
      :visible.sync="dialogVisible"
      width="430px"
    >
      <div v-if="changItem.key == 7">
        <el-radio-group v-model="changItem.radio">
          <el-radio :label="'1'">是</el-radio>
          <el-radio :label="'0'">否</el-radio>
        </el-radio-group>
      </div>
      <div v-if="changItem.key == 8">
        <el-radio-group v-model="changItem.radio">
          <el-radio :label="'1'">小程序</el-radio>
          <el-radio :label="'2'">h5</el-radio>
        </el-radio-group>
      </div>
      <div slot="footer" class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changOnset">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 更新操作弹窗 -->
    <el-dialog
      v-if="dialogVisset"
      title="更新"
      :visible.sync="dialogVisset"
      width="550px"
    >
      <el-form
        ref="queryForm"
        :model="setItem"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item prop="backendName" label="支付方式：">
          <span>{{ setItem.backendName }}</span>
        </el-form-item>
        <el-form-item prop="typeName" label="支付类型：">
          <span>{{ setItem.typeName }}</span>
        </el-form-item>
        <el-form-item prop="logoIconAddress" label="支付logo：">
          <div v-if="setItem.logoIconAddress">
            <UpimgList
              :imgnumber="1"
              :disabled="false"
              :imglist="setItem.logoIconAddress"
              :imageSize="3"
              :nourl="true"
            />
          </div>
        </el-form-item>
        <el-form-item label="支付渠道：" prop="paymentType">
          <el-select v-model="setItem.paymentType" placeholder="请选择">
            <el-option
              v-for="item in setItem.paymentChannel"
              :key="item.paymentType"
              :label="item.name"
              :value="item.paymentType"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="whetherSeparate" label="是否线上分账：">
          <span>{{ setItem.whetherSeparate == 0 ? "是" : "否" }}</span>
        </el-form-item>
        <el-form-item label="是否ERP结算：" prop="erpAutoSettle">
          <el-radio-group v-model="setItem.erpAutoSettle">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="支付应用端：" prop="paymentClient">
          <el-checkbox-group v-model="setItem.paymentClient">
            <el-checkbox :label="1">APP</el-checkbox>
            <el-checkbox :label="2" :disabled="setItem.backendName == '支付宝'"
              >小程序</el-checkbox
            >
            <el-checkbox :label="3">PC</el-checkbox>
            <el-checkbox :label="4">H5</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="还款应用端：" prop="repaymentClient">
          <el-checkbox-group v-model="setItem.repaymentClient">
            <el-checkbox :label="1">APP</el-checkbox>
            <el-checkbox :label="2" :disabled="setItem.backendName == '支付宝'"
              >小程序</el-checkbox
            >
            <el-checkbox :label="3">PC</el-checkbox>
            <el-checkbox :label="4">H5</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="text-right">
        <el-button @click="dialogVisset = false">取 消</el-button>
        <el-button type="primary" @click="setOnItem">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getValue, //全局配置-获取
  setValue, //全局配置-设置
  paymentTypelist, //支付配置-支付方式列表
  paymentTypedetail, //支付配置-支付方式详情
  paymentTypeedit, //支付配置-支付方式编辑
  modifyName, //支付配置-修改支付方式名称
} from "@/api/apiAll/phpUrl";
import UpimgList from "@/components/Updata/UpimgList.vue";
export default {
  name: "",
  components: {
    UpimgList,
  },
  data() {
    return {
      loading: false,
      tableData: [],

      value1: "",
      value2: "",
      dialogVisible: false, //修改弹窗
      //修改弹窗数据
      changItem: {
        key: "",
        title: "",
        radio: "",
      },

      dialogVisset: false,
      setItem: {
        backendName: "", //支付方式
        typeName: "", //支付类型
        paymentType: "",
        logoIconAddress: [], //支付logo
        channelName: "", //支付通道名称
        paymentChannel: [], //支付通道数据
        whetherSeparate: "", //是否线上分账（0 ： 是， 1 ： 否）
        erpAutoSettle: "", //是否erp自动结算（0：是 ，1：否）
        paymentClient: [], //支付应用端选中数据（1: APP 2:小程序 3:PC  4:h5）
        repaymentClient: [], //还款应用端选中数据（1: APP 2:小程序 3:PC  4:h5）
      },
      rules: {
        logoIconAddress: [
          { required: true, message: "请上传支付logo", trigger: "change" },
        ],
        paymentType: [
          { required: true, message: "请选择支付渠道", trigger: "change" },
        ],
        erpAutoSettle: [
          { required: true, message: "请选择是否ERP结算", trigger: "change" },
        ],
        paymentClient: [
          { required: true, message: "请选择支付应用端", trigger: "change" },
        ],
      },
    };
  },
  created() {
    // this.getItem();
  },
  methods: {
    getItem() {
      //** 获取分享清单现金是否免登录 */
      this.getValue1();
      //** 获取分享清单方式 */
      this.getValue2();
      //** 获取支付方式通道 */
      this.getList();
    },
    getValue1() {
      getValue({
        key: 7,
      }).then((res) => {
        if (res.code == 200) {
          this.value1 = res.data[0].value;
        }
      });
    },
    getValue2() {
      getValue({
        key: 8,
      }).then((res) => {
        if (res.code == 200) {
          this.value2 = res.data[0].value;
        }
      });
    },
    //** 获取支付列表 */
    async getList() {
      var res = await paymentTypelist();
      if (res.code == 200) {
        this.tableData = res.data;
      }
      this.tableData.forEach((item) => {
        this.$set(item, "Nametale", false);
      });
    },
    /** 修改现金是否免登录、分享清单方式弹窗 */
    changeSecon(key) {
      this.changItem.key = key;
      if (key == 7) {
        this.changItem.title = "现金是否免登录";
        this.changItem.radio = this.value1;
      }
      if (key == 8) {
        this.changItem.title = "分享清单方式";
        this.changItem.radio = this.value2;
      }
      this.dialogVisible = true;
    },
    /** 确定 */
    changOnset() {
      setValue({
        key: this.changItem.key,
        value: this.changItem.radio,
      }).then((res) => {
        if (res.code == 200) {
          if (this.changItem.key == 7) {
            this.getValue1();
          }
          if (this.changItem.key == 8) {
            this.getValue2();
          }
          this.dialogVisible = false;
          this.$message.success("修改成功");
        }
      });
    },
    showInput(row, index, event) {
      this.tableData.forEach((item) => {
        item.Nametale = false;
      });
      row.Nametale = true;
      this.$nextTick(() => {
        //设置input聚焦
        event.currentTarget.firstElementChild.firstElementChild.focus();
      });
    },
    /** 更改前端显示名称 */
    async Changenameting(row) {
      if (row.backendName == "") return this.$message.error("支付方式为空");
      if (row.frontName == "") return this.$message.error("前端显示名称为空");
      var res = await modifyName({
        backendName: row.backendName,
        frontName: row.frontName,
      });
      if (res.code == 200) {
        this.$message.success("修改成功");
      }
      this.getList();
    },
    /** 更新操作弹窗 */
    async update(row) {
      if (row.backendName == "") return this.$message.error("支付方式为空");
      let res = await paymentTypedetail({ backendName: row.backendName });
      if (res.code == 200) {
        Object.assign(this.setItem, this.$options.data().setItem);
        Object.assign(this.setItem, res.data);
        this.setItem.typeName = row.typeName;
        this.setItem.logoIconAddress = [this.setItem.logoIconAddress];
      }
      this.dialogVisset = true;
    },
    /** 更新 */
    setOnItem() {
      this.$refs.queryForm.validate(async (valid) => {
        if (valid) {
          var data = { ...this.setItem };
          data.logoIconAddress = data.logoIconAddress[0];
          let res = await paymentTypeedit(data);
          if (res.code == 200) {
            this.$message.success("修改成功");
            this.dialogVisset = false;
            this.getList();
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
